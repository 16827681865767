@import url("https://fonts.googleapis.com/css?family=Bree+Serif|Poppins|Source+Sans+Pro|Montserrat:400,900&display=swap");

* {
  /* margin: 0;
  padding: 0; */
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Poppins", sans-serif;
}
html{
  height: 100%;
}

body {
  margin: 0px;
  background-color: black;
  height: 100%;
}

#root{
  height: 100%;
}

.bodyRTL{
  direction: rtl;
}

.bodyLTR{
  direction: ltr;
}

.spinnerViewContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container {
  max-width: 910px;
  margin: 0 auto;
  padding: 0px;
  position: relative;
}

.menu {
  font-family: sans-serif;
  font-size: 14px;
  margin-top: 25px;
}

.menu-group-heading-container{
  width: 100%;
}

.menu-group-heading {
  margin: auto;
  margin: 0;
  padding-bottom: 1em;
  color: rgb(204, 157, 105);
  font-size: xx-large;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.menu-group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5em;
  padding: 1.5em 0;
}

.menu-group-category-heading {
  margin: 0;
  padding-bottom: 1em;
  color: rgb(204, 157, 105);
  font-size: large;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin-top: 15px;
}

.menu-group-image-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.menu-group-image-container img {
  border-radius: 20%;
  width: 100%;
  height: 380px;
  object-fit: cover;
}

.menu-item {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

.menu-item-img {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
  margin-right: 1.5em;
}

.menu-item-text {
  flex-grow: 1;
  color: white;
}

.menu-item-heading {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.menu-item-name-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-item-name {
  color: white;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.menu-item-desc-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-item-desc {
  line-height: 1.6;
  color: white;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0px !important;
}

.navigator{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 5px;
  background-color: rgb(44, 40, 39);
  background-image: linear-gradient(to right, rgba(44, 40, 39, 1), #3d2c2c);
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  position: sticky;
  top: 0;
  width: 100%;
}

.buttons{
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  width: 80%;
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
}

.navigatorButton {
  background: #383b3a !important;
  color: #cc9d69 !important;
  border-radius: 15px !important;
  margin: 2px 8px !important;
  height: 40px !important;
  font-size: medium !important;
  border: 0px !important;
  text-decoration: none !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  font-weight: bold !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-family: "Rubik", sans-serif !important;
  font-optical-sizing: auto !important;
  font-style: normal !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.noIOSFirefix{
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: #383b3a !important;
  color: #cc9d69 !important;
  border-radius: 15px !important;
  margin: 2px 8px !important;
  height: 40px !important;
  font-size: medium !important;
  border: 0px !important;
  text-decoration: none !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  font-weight: bold !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-family: "Rubik", sans-serif !important;
  font-optical-sizing: auto !important;
  font-style: normal !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.navigatorButton:hover {
  background: #1bbb85;
  color: #383b3a;
  border-radius: 5px; 
}

.menu-container{
  background-color: rgb(44, 40, 39);
  background-image: linear-gradient(to right, rgba(44, 40, 39, 1), #3d2c2c);
  padding: 20px;
}

.introduction{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(44, 40, 39);
    padding-top: 160px;
    padding-bottom: 105px;
    background-image: linear-gradient(to right, rgba(44, 40, 39, 1), #3d2c2c);
    height: 100%;
}

.LandingExperianceContainer{
  color: rgb(204, 157, 105);
}

.LandingExperianceContainer h2{
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  padding-left: 5px;
  padding-right: 5px;
}

.LandingLogoContainer{
  text-align: center;
  color: #2bffa9;
  margin: 40px auto auto auto;
}

.vendorLogoImage{
  width: 90%;
}

.languages{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.languages .buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
}

.LanguageSelectorLabel{
  padding-right: 5px;
  padding-left: 5px;
}

button {
  background-color: transparent;
  border: none;
  color: rgb(204, 157, 105);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  border-bottom: 1px solid rgb(204, 157, 105);
}

span{
  font-size: 17px;
}

.noise {
  background-color: #444;
  color: white;
  padding: 10px;
  span{
    text-decoration: underline;
    color: rgb(201, 157, 110);
  }
}

.menu-group-shisha {
  border-left: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .menu {
    font-size: 16px;
  }

  .menu-group {
    grid-template-columns: repeat(2, 1fr);
  }

  .menu-item-img {
    width: 125px;
    height: 125px;
    margin-top: 50px;
  }
}
